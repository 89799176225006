import React from "react";
import Layout from "../components/layout";
import { termsAndCond } from "../utilities/tncdata";

import "./styles/tncs.css";

function TermsAndConditions(){
    return (
        <Layout>
            <h2 className="tncs-header"> {"Terms and Conditions"} </h2>
            <div className="tncs">
                {termsAndCond.map( ele => (
                    <div>
                        <h3> {ele.title}  </h3>
                        <div> {ele.description} </div>
                    </div>
                ) )}
                    
            </div>
        </Layout>
    )
}


export default TermsAndConditions;